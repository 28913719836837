<template>
  <div
    v-if="showVideoBanner"
    style="display: block;
          position: fixed;
          z-index: 6;"
    :style="`${
      $device.isDesktop
        ? `left:5px;bottom:5px;`
        : `right:5px;bottom:50px;`
    }`"
  >
    <div>
      <div>
        <!-- <span
          v-if="showPopup"
          @click="blocked"
          style="padding: 2px 4px;
                  position: absolute;
                  top: -60px;
                  right: 0px;
                  background-color: #fff;
                  padding: 2px 14px;
                  border-radius: 20px;
                  color: red;
                  font-size: 14px;
                  cursor: pointer;"
        >
          Не показывать сегодня
        </span>
        <button
          v-if="!videoRollCounter"
          @click="showPopup = !showPopup"
          style="position: absolute;
                padding: 0px;
                top: -30px;
                right: 30px;
                width: 24px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                font-size: 1.2rem;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 20px;
                border: none;
                transition: all .2s ease;"
        >
          <i style="display: inline-block;
                    vertical-align: middle;
                    width: 12px;
                    height: 12px;
                    background-image: url('/static_files/icons/setting.png');
                    background-size: 12px;"
          />
        </button> -->
        <!-- <button
          @click="actionDislike"
          style="position: absolute;
                padding: 0px;
                top: -36px;
                right: 112px;
                width: 32px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                font-size: 1.2rem;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.75);
                border-radius: 20px;
                border: none;
                transition: all .2s ease;"
        >
          <i style="display: inline-block;
                    vertical-align: middle;
                    width: 20px;
                    height: 20px;
                    background-image: url('/static_files/icons/dislike.png');
                    background-size: 20px;"
          />
        </button>
        <button
          @click="actionLike"
          style="position: absolute;
                padding: 0px;
                top: -36px;
                right: 70px;
                width: 32px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                font-size: 1.2rem;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.75);
                border-radius: 20px;
                border: none;
                transition: all .2s ease;"
        >
          <i style="display: inline-block;
                    vertical-align: middle;
                    width: 20px;
                    height: 20px;
                    background-image: url('/static_files/icons/like.png');
                    background-size: 20px;"
          />
        </button> -->
        <button
          v-if="videoRollCounter"
          style="position: absolute;
                padding: 0px;
                top: -30px;
                right: 0px;
                width: 24px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                font-size: 1.2rem;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 50%;
                border: none;
                transition: all .2s ease;"
        >
          {{ videoRollCounter }}
        </button>
        <button
          @click="closeVideoBanner"
          style="position: absolute;
                padding: 0px;
                top: -30px;
                right: 30px;
                width: 24px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                font-size: 1.2rem;
                background-color: rgba(0, 0, 0, 0.75);
                color: #000;
                border-radius: 50%;
                border: none;
                transition: all .2s ease;"
        >
          <i style="display: inline-block;
                    width: 8px;
                    height: 8px;
                    background-image: url('/static_files/icons/cancel-dark.svg');
                    background-size: 8px;
                    vertical-align: middle;"
          />
        </button>
      </div>
    </div>

    <div>
      <div style="display: grid;
                grid-template-columns: repeat(2, 1fr);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                padding: 3px 10px;
                font-size: 1.4rem;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.75);
                z-index: 2;
                cursor: pointer;"
      >
        <div>
          <nuxt-link to="/tv" style="color: #fff;">
            Чат трансляции
          </nuxt-link>
        </div>
        <span @click="$store.commit('toggleFeedback', true)" style="justify-self: end;">
          Жалоба
        </span>
      </div>
      <div style="background-color:#000;width:320px;height:200px;">
        <iframe
          ref="chiframe"
          src=""
          style="position: relative;
                width: 100%;
                height: 100%;
                border: none;
                z-index: 1;"

        />
      </div>
      <!-- <i class="loader" /> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  watch: {
    videoRollCounter(val) {
      if (+val <= 0) {
        this.showVideoBanner = false
        clearInterval(this.interval)
      }
    },
    changeRoute(val) {
      if (!this.showVideoBanner) {
        this.start()
      }
    }
  },
  computed: {
    ...mapState(['user']),
    changeRoute() {
      return this.$route.name
    }
  },
  data() {
    return {
      showVideoBanner: false,
      videoRollInterval: null,
      videoRollCounter: 45,
      timeout: null,
      showPopup: false,
      currentFilm: null,
      mvAdsInterval: null
    }
  },
  mounted() {
    clearInterval(this.interval)

    this.timeout = setTimeout(() => {
      this.start()
    }, 60 * 1000)

    // if (!this.$device.isDesktop) {
    //   this.mvAdsInterval = setInterval(() => {
    //     const elem = document.querySelector('.m-ads')
    //     if (elem && elem.style.opacity && elem.style.opacity < 1) {
    //       this.showVideoBanner = false
    //     }
    //   }, 1000)
    // }
  },
  methods: {
    // async actionLike() {
    //   if (!this.user) {
    //     this.$store.commit('setShowAuthForm', true)
    //     return
    //   }

    //   const body = {
    //     channel_name: this.currentFilm.film.title_en
    //   }
    //   const { data } = await this.$axios.post(`/api/partners/rating`, body)
    //   if (data.error) {
    //     this.$store.commit('setError', { status: 'warn', msg: data.error.message })
    //   } else {
    //     this.$store.commit('setError', { status: 'ok', msg: 'Спасибо за поддержку!' })
    //   }
    //   setTimeout(() => this.$store.commit('clearError'), 2000)
    // },
    // async actionDislike() {
    //   const date = `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`
    //   localStorage.setItem(`streams_block_${this.currentFilm.film.title_en}_${date}`, 'true')
    //   this.showPopup = false
    //   this.closeVideoBanner()
    //   const body = {
    //     channel_name: this.currentFilm.film.title_en
    //   }
    //   await this.$axios.post(`/api/partners/block`, body)
    // },
    parseBB() {
      setTimeout(() => {
        const list = document.querySelectorAll('.messages li p')
        if (list) {
          Array.from(list).forEach((el) => {
            const match = el.textContent.match(/\[::.*::\]/)
            if (match) {
              const p = el
              let smilesCat = 'onion'
              if (p.textContent.includes('mafu_')) {
                smilesCat = 'mafu'
              } else if (p.textContent.includes('anityanochki_')) {
                smilesCat = 'anityanochki'
              }
              const str = p.textContent
                .replace(/\[::link="/g, `<a href="`)
                .replace(/"::\]/g, `">`)
                .replace(/\[::\/link::\]/g, '</a>')
                .replace(/\[::/g, `<img src="/static_files/onion/`)
                .replace(/::\]/g, `.gif" class="chat-emoji ${smilesCat}" />`)
              p.innerHTML = str
            }
          })
        }
        this.$nextTick(() => this.scrollChatMessages())
        const mpList = document.querySelectorAll('.message-push p')
        if (mpList) {
          Array.from(mpList).forEach((el) => {
            const match = el.textContent.match(/\[::.*::\]/)
            if (match) {
              const p = el
              const str = p.textContent
                .replace(/\[::/g, '<img src="/static_files/onion/')
                .replace(/::\]/g, '.gif" class="chat-emoji" />')
              p.innerHTML = str
            }
          })
        }
      }, 100)
    },
    start() {
      clearTimeout(this.timeout)

      const timeout = localStorage.getItem('tv_close_timeout')

      if (timeout && Date.now() < +timeout) return

      // if (
      //   this.$route.name === 'tv' // || this.$route.name === 'cinema-id'
      // ) {
      //   this.timeout = null
      //   this.showVideoBanner = false
      //   return
      // }
      this.getTv()
    },
    async getTv() {
      const { data } = await this.$axios.get('/api/tv')
      if (data.lenta.length) {
        const currentTime = data.current_time

        const findItems = data.lenta.filter(el => el.start < currentTime && el.end > currentTime)

        let currentFilm = null

        let currentDuration = 0

        if (findItems.length) {
          const findTwitch = findItems.filter(el => el.type === 'twitch')

          if (findTwitch.length) {
            let index = 0
            if (findTwitch.length > 1) {
              const rand = Math.floor(Math.random() * ((findTwitch.length - 1) - 0 + 1)) + 0
              index = rand
            }
            currentFilm = findTwitch[index]
            currentDuration = currentTime - findTwitch[index].start

            const date = `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`
            if (localStorage.getItem(`streams_block_${currentFilm.film.title_en}_${date}`)) return

            this.currentFilm = currentFilm

            this.$store.commit('setInviteToStream', currentFilm)

            if (this.user && this.user.group === 777) return

            const body = {
              channel_name: findTwitch[index].film.title_en
            }
            this.$axios.post(`/api/partners/stats`, body)
          } else {
            currentFilm = findItems[0]
            currentDuration = currentTime - findItems[0].start
            currentFilm.link += `&start_from=${currentDuration}`
          }

          this.showVideoBanner = true

          this.videoRollCounter = currentFilm.type === 'twitch' ? 45 : 10

          this.videoRollInterval = setInterval(() => {
            if (this.videoRollCounter) {
              --this.videoRollCounter
            } else {
              clearInterval(this.videoRollInterval)
            }
          }, 1000)

          setTimeout(() => {
            const iframe = this.$refs.chiframe
            if (iframe) {
              iframe.src = currentFilm.link
              iframe.contentWindow.postMessage({ key: "kodik_player_api", value: { method: "play" } }, '*')
            }
          }, 1000)
        }
      }
    },
    closeVideoBanner() {
      this.showVideoBanner = false
      clearInterval(this.videoRollInterval)
      const timeout = Date.now() + 1000 * 300
      localStorage.setItem('tv_close_timeout', JSON.stringify(timeout))
    }
  }
}
</script>
